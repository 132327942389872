import React from 'react'
import ServiceDetailsSidebar from './ServiceDetailsSidebar'
import RelatedServices from './RelatedServices'
import DocumentCard from './DocumentCard'
import {getConversion} from '../../api/images'

const ServiceDetailsContent = ({ documents, service, services }) => {
    const { body, instructions, media, name } = service

    const openTabSection = (evt, tabNmae) => {
        let i, tabcontent, tablinks;
        tabcontent = document.getElementsByClassName("tabs_item");
        for (i = 0; i < tabcontent.length; i++) {
            tabcontent[i].classList.remove("fadeInUp");
            tabcontent[i].style.display = "none";
        }

        tablinks = document.getElementsByClassName("btn-link");
        for (i = 0; i < tablinks.length; i++) {
            tablinks[i].className = tablinks[i].className.replace(" current", "");
        }

        document.getElementById(tabNmae).style.display = "block";
        document.getElementById(tabNmae).className += " fadeInUp animated";
        evt.currentTarget.className += " current";
    }

    return (
        <>
            <div className='services-details-area pb-100'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-lg-8 col-md-12'>
                            <div className='services-details-desc'>
                                <ul className='nav nav-tabs' id='myTab'>
                                    <li>
                                        <button
                                            className='btn btn-link current'
                                            onClick={(e) => openTabSection(e, 'tab1')}>
                                            Descripción
                                        </button>
                                    </li>
                                    <li>
                                        <button
                                            className='btn btn-link'
                                            onClick={(e) => openTabSection(e, 'tab2')}>
                                            Instrucciones
                                        </button>
                                    </li>
                                    {!! documents.length > 0 &&  
                                    <>
                                    <li>
                                        <button
                                            className='btn btn-link'
                                            onClick={(e) => openTabSection(e, 'tab3')}>
                                            Documentos
                                        </button>
                                    </li>
                                    </>
                                    }
                                    
                                </ul>
                                <div className='tab-content px-0'>
                                    <div id='tab1' className='tab-pane tabs_item'>
                                        <div className='services-overview'>
                                            {media.length > 0 ?
                                                <img src={getConversion(media[0])} alt={name} className="w-100" />
                                                :
                                                null
                                            }
                                            <h3>Descripción del Servicio</h3>
                                            <p>{body}</p>
                                            {service.facility_id && (service.facility.mo1 || service.facility.tu1 || service.facility.we1 || service.facility.th1 || service.facility.fr1 || service.facility.sa1 || service.facility.su1) &&
                                                <>
                                                    <h5>Horario</h5>
                                                    <table className="table table-sm table-responsive text-center">
                                                        <thead>
                                                            <tr className="">
                                                                <th></th>
                                                                <th colSpan="2">Mañana</th>
                                                                <th colSpan="2">Tarde</th>

                                                            </tr>
                                                            <tr>
                                                                <th></th>
                                                                <th>Inicio</th>
                                                                <th>Fin</th>
                                                                <th>Inicio</th>
                                                                <th>Fin</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr>
                                                                <td>
                                                                    <label htmlFor="break" className="form-label">Lunes</label>
                                                                </td>
                                                                <td className="text-center">
                                                                    {service.facility.mo1 && service.facility.mo1.slice(0, -3)}
                                                                </td>
                                                                <td className="text-center">
                                                                    {service.facility.mo2 && service.facility.mo2.slice(0, -3)}
                                                                </td>
                                                                <td className="text-center">
                                                                    {service.facility.mo3 && service.facility.mo3.slice(0, -3)}
                                                                </td>
                                                                <td className="text-center">
                                                                    {service.facility.mo4 && service.facility.mo4.slice(0, -3)}
                                                                </td>

                                                            </tr>
                                                            <tr>
                                                                <td>
                                                                    <label htmlFor="break" className="form-label">Martes</label>
                                                                </td>
                                                                <td className="text-center">
                                                                    {service.facility.tu1 && service.facility.tu1.slice(0, -3)}
                                                                </td>
                                                                <td className="text-center">
                                                                    {service.facility.tu2 && service.facility.tu2.slice(0, -3)}
                                                                </td>
                                                                <td className="text-center">
                                                                    {service.facility.tu3 && service.facility.tu3.slice(0, -3)}
                                                                </td>
                                                                <td className="text-center">
                                                                    {service.facility.tu4 && service.facility.tu4.slice(0, -3)}
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>
                                                                    <label htmlFor="break" className="form-label">Miercoles</label>
                                                                </td>
                                                                <td className="text-center">
                                                                    {service.facility.we1 && service.facility.we1.slice(0, -3)}
                                                                </td>
                                                                <td className="text-center">
                                                                    {service.facility.we2 && service.facility.we2.slice(0, -3)}
                                                                </td>
                                                                <td className="text-center">
                                                                    {service.facility.we3 && service.facility.we3.slice(0, -3)}
                                                                </td>
                                                                <td className="text-center">
                                                                    {service.facility.we4 && service.facility.we4.slice(0, -3)}
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>
                                                                    <label htmlFor="break" className="form-label">Jueves</label>
                                                                </td>
                                                                <td className="text-center">
                                                                    {service.facility.th1 && service.facility.th1.slice(0, -3)}
                                                                </td>
                                                                <td className="text-center">
                                                                    {service.facility.th2 && service.facility.th2.slice(0, -3)}
                                                                </td>
                                                                <td className="text-center">
                                                                    {service.facility.th3 && service.facility.th3.slice(0, -3)}
                                                                </td>
                                                                <td className="text-center">
                                                                    {service.facility.th4 && service.facility.th4.slice(0, -3)}
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>
                                                                    <label htmlFor="break" className="form-label">Viernes</label>
                                                                </td>
                                                                <td className="text-center">
                                                                    {service.facility.fr1 && service.facility.fr1.slice(0, -3)}
                                                                </td>
                                                                <td className="text-center">
                                                                    {service.facility.fr2 && service.facility.fr2.slice(0, -3)}
                                                                </td>
                                                                <td className="text-center">
                                                                    {service.facility.fr3 && service.facility.fr3.slice(0, -3)}
                                                                </td>
                                                                <td className="text-center">
                                                                    {service.facility.fr4 && service.facility.fr4.slice(0, -3)}
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>
                                                                    <label htmlFor="break" className="form-label">Sábado</label>
                                                                </td>
                                                                <td className="text-center">
                                                                    {service.facility.sa1 && service.facility.sa1.slice(0, -3)}
                                                                </td>
                                                                <td className="text-center">
                                                                    {service.facility.sa2 && service.facility.sa2.slice(0, -3)}
                                                                </td>
                                                                <td className="text-center">
                                                                    {service.facility.sa3 && service.facility.sa3.slice(0, -3)}
                                                                </td>
                                                                <td className="text-center">
                                                                    {service.facility.sa4 && service.facility.sa4.slice(0, -3)}
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>
                                                                    <label htmlFor="break" className="form-label">Domingo</label>
                                                                </td>
                                                                <td className="text-center">
                                                                    {service.facility.su1 && service.facility.su1.slice(0, -3)}
                                                                </td>
                                                                <td className="text-center">
                                                                    {service.facility.su2 && service.facility.su2.slice(0, -3)}
                                                                </td>
                                                                <td className="text-center">
                                                                    {service.facility.su3 && service.facility.su3.slice(0, -3)}
                                                                </td>
                                                                <td className="text-center">
                                                                    {service.facility.su4 && service.facility.su4.slice(0, -3)}
                                                                </td>

                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </>
                                            }
                                        </div>
                                    </div>
                                    <div id='tab2' className='tab-pane tabs_item'>
                                        <div className='services-overview'>
                                            <h3>Instrucciones del Servicio</h3>
                                            <p>{instructions ?? 'No dispone de instrucciones'}</p>
                                        </div>
                                    </div>
                                    <div id='tab3' className='tab-pane tabs_item'>
                                        <div className='services-overview'>
                                            <h3>Documentos relacionados</h3>
                                            {documents.length > 0 ?
                                                documents.map((document) => {
                                                    return document.media.map((media) => {
                                                        return (
                                                            <DocumentCard
                                                                key={`document-${document.id}`}
                                                                media={media}
                                                            />
                                                        )
                                                    })
                                                })
                                                :
                                                <p>No dispone de archivos</p>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-4 col-md-12'>
                            <ServiceDetailsSidebar
                                service={service}
                            />
                        </div>
                    </div>
                </div>
            </div>
            {services && services.length > 0 ?
                <RelatedServices
                    services={services}
                />
                :
                null
            }
        </>
    )
}

export default ServiceDetailsContent